<template>
  <div class="component card" id="location" ref="location">
    <div class="title">
      <h1 v-if="displayLocationWarning" class="error-style">We're not yet in {{ this.reverseGeocodeRegion.text }}</h1>
      <div v-else>
        <h1>Where should we sniff?</h1>
        <p class="error" v-if="errorStr">There was an error getting your location: <u>{{ errorStr }}.</u><br>Try entering your address instead.</p>
        <p v-else>Bottlebeagle automatically watches for online and retail stock in any location you choose.</p>
      </div>

    </div>

    <div class="card-content" v-if="gettingLocation">
        <div class="getting-location">
          <div class="loader"></div>
        </div>
    </div>
    <!-- <div class="card-content" style="height: unset" v-else-if="displayLocationWarning">
      <div class="location-warning">
        <p>With Bottlebeagle, each alert is for a specific region. So when your selected location doesn't match the region the bottle is in, it means you would only get alerts for online stock.</p>
        <button class="big-button" @click="displayRegionSignupModal = !displayRegionSignupModal">Get notified when we're available in {{ this.reverseGeocodeRegion.text }}</button>
      </div>
    </div> -->

    <div class="card-content" v-else>
      <div class="map-meta-container" v-if="location" ref="map">
        <base-map v-if="location" v-bind:coordinates="location.coords" v-bind:radius="radius" v-bind:watchWholeRegion="watchWholeRegion" @map-loaded="mapWasLoaded"></base-map>
        <div v-else-if="gettingLocation" class="loader">
        </div>
      </div>
      <div v-else class="location-chooser">
        <address-selection v-if="enterAddress" @addressSelected="selectedAddress"></address-selection>
        <div v-else class="options">
          <div class="big-option" @click="locateMe">
            <img src="https://img.icons8.com/ios/50/000000/gps-device.png" srcset="https://img.icons8.com/ios/100/000000/gps-device.png 2x, https://img.icons8.com/ios/150/000000/gps-device.png 3x"/>
            <p>Get my location</p>
          </div>
          <div class="big-option" @click="toggleEnterAddress">
            <img src="https://img.icons8.com/ios/50/000000/worldwide-location.png" srcset="https://img.icons8.com/ios/100/000000/worldwide-location.png 2x, https://img.icons8.com/ios/150/000000/worldwide-location.png 3x"/>
            <p>Enter an address</p>
          </div>
        </div>
      </div>

    </div>
    <div class="location-choosing" v-if="location && !displayLocationWarning">
      <div class="all-region-checkbox">
        <input type="checkbox" v-model="watchWholeRegion">
        <label for="checkbox">{{ watchRegionCheckboxLabel }}</label>
      </div>
      <div class="radius" v-bind:class="{'disabled': watchWholeRegion}">
        <p>1km</p>
        <input type="range" min="1" max="100" v-model="internalRadius" class="slider" :disabled="watchWholeRegion">
        <p>1000km</p>
      </div>
    </div>

    <div v-if="location && !displayLocationWarning && currentStep.stepNumber == 1" class="card-footer">
      <a @click="location = null; displayLocationWarning=false;">
        <div class="footer-back">
          <p>Select another address</p>
        </div>
      </a>
      <button class="big-button" v-bind:disabled="confirmationLoading" @click="submitLocation">{{ confirmButtonString }}</button>
      <div class="footer-right"></div>
    </div>
    <div v-else-if="displayLocationWarning && currentStep.stepNumber == 1" class="card-footer" style="justify-content: center; gap: 14px;">
      <a @click="location = null; displayLocationWarning=false;">
        <div class="footer-back">
          <p>Select another address</p>          
        </div>
      </a>
      <button class="big-button" @click="resetSearchAndRegion">New search</button>
      <div class="footer-right"></div>
    </div>
    <!-- <confirm-modal 
      v-if="displayConfirmModal" 
      @destructive="confirmBadLocation" 
      @gentle="hideCancelModal" 
      v-bind:title="'Your location looks wrong!'" 
      v-bind:subtitle="badLocationSubtitle"
      v-bind:body="'You should start your search in the region you plan to get an alert in, or else your alert might not work as expected.'" 
      v-bind:destructiveButton="'Continue with this location'"
      v-bind:gentleButton="'Try a different address'">
      v-bind:shouldSpin="true"
    </confirm-modal> -->
    <region-promo-modal v-if="displayLocationWarning" v-bind:regionInfo='reverseGeocodeRegion' v-bind:location="location" @regionPromoDismissed="regionPromoDismissed"></region-promo-modal>
  </div>
</template>

<script>
const AddressSelection = () => import('./AddressSelection.vue');
const RegionPromoModal = () => import('./region-promo-modal.vue');

const mbxClient = require('@mapbox/mapbox-sdk');
const mbxGeo = require('@mapbox/mapbox-sdk/services/geocoding');

const baseClient = mbxClient({ accessToken: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN });
const geoservice = mbxGeo(baseClient);

const BaseMap = () => import('./BaseMap.vue')
import axios from 'axios';

export default {
  name: 'Location',
  props: ['region'],
  components: {BaseMap, AddressSelection, RegionPromoModal},
  data() {
    return {
      enterAddress: false,
      address: null,
      location: null,
      gettingLocation: false,
      errorStr: null,
      internalRadius: "33",
      reverseGeocodeRegion: null,
      displayLocationWarning: false,
      watchWholeRegion: false,
      confirmationLoading: false,
    };
  },
  computed: {
    currentStep() {
      return this.$store.getters['step']
    },
    watchRegionCheckboxLabel() {
      if (this.reverseGeocodeRegion) {
        return "Watch all of " + this.reverseGeocodeRegion.text;
      } else {
        return "Watch all the region";
      }
    },
    confirmButtonString() {
      if (this.confirmationLoading) {
        return "Loading...";
      }
      else if (this.watchWholeRegion) {
        if (this.reverseGeocodeRegion) {
          return "Watch all of " + this.reverseGeocodeRegion.text;
        } else {
          return "Watch all of the region";
        }
      } else {
        let res = this.radius / 1000;
        return "Watch within " + Math.ceil(res) + "km of you";
      }
    },
    radius() {
        // position will be between 0 and 100
      if (this.watchWholeRegion) {
        return 0;
      }
      
      var minp = 1;
      var maxp = 100;

      // The result should be between 100 an 10000000
      var minv = Math.log(1000);
      var maxv = Math.log(1000000);

      // calculate adjustment factor
      var scale = (maxv-minv) / (maxp-minp);

      return Math.exp(minv + scale*(this.internalRadius-minp));
    },
    badLocationSubtitle: function () {
      return "You've want alerts about a bottle in " + this.region.name + ", but your location is in " + this.reverseGeocodeRegion.text + ".";
    },
  },
  mounted() {
    if (!this.$route.query.itemId || !this.$route.query.retailer) {
      this.scrollToCenterElement();
    }

  },
  methods: {
    mapWasLoaded () {
      this.$refs.map.scrollIntoView({ block: 'center', scrollBehavior: 'smooth' });
    },
    selectedAddress (locObj) {
      this.location = locObj;
      console.log("selected address:" + locObj);
      this.getReverseGeocodeRegion(this.location.coords).then(() => {
      });
    },
    async locateMe() {
      // gets geolocation and sets it
      this.gettingLocation = true;

      this.getLocation()
        .then(pos => { // get the location
          console.log("received pos:" + pos);
          this.location = pos;
          this.gettingLocation = false;
          console.log("getting reverse geocode region for pos:" + pos);
          this.getReverseGeocodeRegion(pos.coords).then(() => {
            console.log("got reverse geocode region for pos:" + pos);
          });
        }).catch(err => {
          console.log("Error getting location:" + err);
          this.errorStr = err.message;
          this.gettingLocation = false;
        })
    },
    async getLocation() {
      // actually does the geolocating
      return new Promise((resolve, reject) => {
        if (!("geolocation" in navigator)) {
          reject(new Error('Geolocation is not available.'));
        }

        navigator.geolocation.getCurrentPosition(pos => {
          resolve(pos);
        }, err => {
          reject(err);
        });
      });
    },
    toggleEnterAddress() {
      this.enterAddress = !this.enterAddress;
    },
    submitLocation() {
      var data = {
        latitude: this.location.coords.latitude,
        longitude: this.location.coords.longitude,
        radius: this.radius,
        cart: this.$store.getters['cart/cartItems'],
      };
      // set region if it's there
      if (this.region) {
        data.region = this.region.region;
      }
      this.confirmationLoading = true;
      axios.post('/checkout/location/', data).then((res) => {
        // update the store with the new cart stock statues
        this.confirmationLoading = false;
        this.$store.dispatch('cart/updateCartStatus', res.data.stock_status);
        // get upgrade_info or set it to false
        let upgrade_info = res.data.upgrade_pricing ? res.data.upgrade_pricing : false;
        this.$emit('located', this.location.coords, this.radius, res.data.pricing, res.data.regions, res.data.pricing_region, upgrade_info, res.data.retailers);
      }).catch((err) => {
        // handle the 404
        this.confirmationLoading = false;
        if (err.response.status === 404) {
          this.displayLocationWarning = true;
          // this.displayConfirmModal = true;
        }
        console.log("Error submitting location:" + err);
      });
    },
    async getReverseGeocodeRegion(coords) {
      console.log("getting reverse geocode region for coords:" + coords);
      return new Promise((resolve, reject) => {
        geoservice.reverseGeocode({
          query: [coords.longitude, coords.latitude],
          types: ["region"],
          limit: 1,
        }).send()
        .then(function (response) {
          let feature = response.body.features[0];
          this.reverseGeocodeRegion = feature;
        }.bind(this)).catch(error => {
          // uhhh
          console.log("Error reverse geocoding:" + error);
          reject(error);
        });
      });
    },
    async matchesRegion(coords, region) {
      return new Promise((resolve, reject) => {
        geoservice.reverseGeocode({
          query: [coords.longitude, coords.latitude],
          types: ["region"],
          limit: 1,
        }).send()
        .then(function (response) {
          let feature = response.body.features[0];
          let shortCode = feature.properties.short_code.split('-').pop();
          let regionName = feature.text;
          let countryShortCode = feature.context[0].short_code;

          this.reverseGeocodeRegion = feature;

          if (region.region.toLowerCase() === shortCode.toLowerCase()) {
            console.log("its a match between region and reverse geocode:" + region.region + " " + shortCode);
            resolve(true);
          } else {
            resolve(false);
          }
        }.bind(this)).catch(error => {
          // uhhh
          console.log("Error reverse geocoding:" + error);
          reject(error);
        });
      });
    },
    resetSearchAndRegion() {
      this.$emit('resetCheckoutAndRegion');
    },
    hideCancelModal() {
      this.location = null;
      this.enterAddress = true;
      this.displayConfirmModal = false;
    },
    regionPromoDismissed(dismissed, newUser) {
      this.displayLocationWarning = false;

      this.$posthog.capture('unsupported_region', { 'dismissed': dismissed, 'newuser': newUser, 'region': this.reverseGeocodeRegion.text });
      
      if (newUser) { // new user was created
        this.$emit('resetCheckoutAndRegion');
      } else {
        this.location = null;
        this.displayLocationWarning=false;
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

a {
  cursor: pointer;
}

.card {
  .title {

  }
  .card-content {
    padding: 0;
    height: 274px;
    background-image: url("../assets/blurrymap.png");
    // backdrop-filter: blur(10px);
    // blur the background
    // filter: blur(5px);

    .map-meta-container{
      width: 100%;
      height: 100%;
    }

    .getting-location {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;

      h1 {
        margin: 0;
      }

      .loader::before {
        content :"Locating...";
      }
    }

    .location-warning {
      // background: #401D18;
      padding: 1em;
      margin: auto auto;
      max-width: 40em;
      display: inline-block;
      color: white;
      h1 {
        margin: auto;
        color: #401D18;
        // max-width: 20em;
        text-decoration: underline;
        text-decoration-color: $emphasis;
      }
    }


    .location-chooser {
      height: 100%;
      width: calc(100% - 20px);
      margin: 0 10px;

      .options {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        // padding: 15px 0;
        width: 100%;
        height: 100%;

        .big-option {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          width: 250px;
          height: 80px;
          padding: 1em;
          border-radius: 5px;
          background: none;
          border: 2px solid white;
          box-shadow: 0px 0px 5px 0px $emphasis;

          cursor: pointer;

          img {
            filter: invert(38%) sepia(89%) saturate(714%) hue-rotate(337deg) brightness(114%) contrast(101%);
          }

          p {
            color: white;
            text-align: center;
            font-family: Assistant;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin: 0;
          }

          &:hover {
            transition:all 0.3s ease;
            border: 2px solid $emphasis;
            background: #863B33;

            img {
              filter: none;
            }

            p {
              color: white;
            }
          }
        }
      }

      #address-selection {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
      }

    }
  }
  .location-choosing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 15px 0 0 0;
    width: 100%;
    height: 100%;

    .all-region-checkbox {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      label {
        // color: white;
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }
    }

    .radius {
      width: 300px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 8px;
        border-radius: 5px;
        background: #401D18;
        outline: none;
      }
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $emphasis;
        cursor: pointer;
      }

      .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: $emphasis;
        cursor: pointer;
      }

    }

    .card-footer {
      border-top: none;
    }
  }
  .radius {
    width: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .slider {
      -webkit-appearance: none;
      width: 100%;
      height: 8px;
      border-radius: 5px;
      background: #401D18;
      outline: none;
    }
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $emphasis;
      cursor: pointer;
    }

    .slider::-moz-range-thumb {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: $emphasis;
      cursor: pointer;
    }

  }

  .card-footer {
    border-top: none;
  }
}



.big-button {
  width: 200px;
  cursor: pointer;
  img {
    position: relative;
    top: 3px;
    margin: 0 0.5em 0 0;
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
    outline: none;
  }
  &.confirm-loading {
    cursor: not-allowed;
    background:#401D18;

  }
}
.radius {
  width: 40%;
  margin: 0 auto;
  ul {
    list-style-type: none;
    text-align: left;
    padding-left: 0;
  }
}
.loader {
  margin: auto;
}
</style>
